import React, { useEffect, useState } from "react";

import { Styled } from "./dashboard.styled";
import Diagram1 from "../../components/Diagrams/diagram1";
import Diagram2 from "../../components/Diagrams/diagram2";
import Diagram3 from "../../components/Diagrams/diagram3";
import Diagram4 from "../../components/Diagrams/diagram4";
import Diagram5 from "../../components/Diagrams/diagram5";
import Diagram6 from "../../components/Diagrams/diagram6";
import axios from "axios";

var config = {
  method: "get",
  url: "https://tis.susiandjames.com/api/kpi",
  headers: {
    Authorization:
      "Basic VElTX2FwaTpCUFZ5KmNhPSEqYV9yXUtGdyw3WTB8clZuPSxyMnk5eXdqUz9KW3pJPntrUkomNXJhMC9SM1p2aEdeMyRVP0cpO1d4dk1MOy1iQEYzZGlQQw==",
  },
};

const handleGetData = async () => {
  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
      return null;
    });
};

const Dashboard = () => {
  const [data, setData] = useState();

  const handleSetData = async () => {
    const data = await handleGetData();
    setData(data);
  };

  useEffect(() => {
    handleSetData();
  }, []);

  return (
    <Styled.MainBox>
      <Styled.HeaderBox>
        <Styled.HeaderBoxItemLeft>KPI Dashboard </Styled.HeaderBoxItemLeft>
        <Styled.HeaderBoxItemRight></Styled.HeaderBoxItemRight>
      </Styled.HeaderBox>

      <Diagram1 data={{ 0: data?.kpi1, 1: data?.kpi2, 2: data?.kpi3 }} />

      <Diagram2 data={{ 0: data?.kpi_D2_1, 1: data?.kpi_D2_2 }} />
      {data && (
        <>
          <Diagram3 data={{ 0: data?.kpi4, 1: data?.kpi5 }} />
          <Diagram4 data={{ 0: data?.kpi6 }} />
          <Diagram5 />
          <Diagram6 data={{ 0: data?.kpi9 }} />
        </>
      )}
    </Styled.MainBox>
  );
};

export default Dashboard;
